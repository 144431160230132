import React from 'react';
import backgroundImage from '../Assets/Page 03/demo.png';
import leftlogo from '../Assets/Page 01/bottomlogo.png'
import MenuText from '../Assets/Page 03/text.png'

const Agenda = () => {
  return (
    <div
    style={{
      display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // 
     
        backgroundRepeat: "round", 

      
    }}
  >
    <p style={{fontFamily:'InterstateBold', color:'#FFC72C', position:'absolute', top:10, left:25, fontSize:20}}>MENU</p>
    <img src={leftlogo} style={{position:'absolute', right:25, top:20, width:'20%'}}></img>
    <img src={MenuText} style={{position:'absolute', width:'80%', height:'78%' }}></img>
   
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
  },
};

export default Agenda;
