import React from "react";
import bg from "../Assets/Page 05/bg.png";
import speaker1des from "../Assets/Page 05/des1.png";
import speaker1 from "../Assets/Page 05/sp1.png";
import speaker2des from "../Assets/Page 05/des2.png";
import speaker2 from "../Assets/Page 05/sp2.png";
import speaker3des from "../Assets/Page 05/des3.png";
import speaker3 from "../Assets/Page 05/sp3.png";
import speaker4des from "../Assets/Page 05/des4.png";
import speaker4 from "../Assets/Page 05/sp4.png";
import speaker5des from "../Assets/Page 05/des5.png";
import speaker5 from "../Assets/Page 05/sp5.png";
import speaker6des from "../Assets/Page 05/des6.png";
import speaker6 from "../Assets/Page 05/sp6.png";
import speaker7des from "../Assets/Page 05/des7.png";
import speaker7 from "../Assets/Page 05/sp7.png";
import leftlogo from "../Assets/Page 01/bottomlogo.png";
import section1 from "../Assets/Page 05/heading.png";
import { display, height, width } from "@mui/system";
import speaker21 from "../Assets/Page 06/sp1.png";
import des21 from "../Assets/Page 06/des1.png";
import speaker22 from "../Assets/Page 06/sp2.png";
import des22 from "../Assets/Page 06/des2.png";
import speaker23 from "../Assets/Page 06/sp3.png";
import des23 from "../Assets/Page 06/des3.png";
import speaker24 from "../Assets/Page 06/sp4.png";
import des24 from "../Assets/Page 06/des4.png";
import speaker25 from "../Assets/Page 06/sp5.png";
import des25 from "../Assets/Page 06/des5.png";
import section2 from "../Assets/Page 06/section.png";
import speaker31 from "../Assets/Page 07/sp1.png";
import des31 from "../Assets/Page 07/des1.png";
import speaker32 from "../Assets/Page 07/sp2.png";
import des32 from "../Assets/Page 07/des2.png";
import speaker33 from "../Assets/Page 07/sp3.png";
import des33 from "../Assets/Page 07/des3.png";
import speaker34 from "../Assets/Page 07/sp4.png";
import des34 from "../Assets/Page 07/des4.png";
import speaker35 from "../Assets/Page 07/sp5.png";
import des35 from "../Assets/Page 07/des5.png";
import speaker36 from "../Assets/Page 07/sp6.png";
import des36 from "../Assets/Page 07/des6.png";
import section3 from "../Assets/Page 07/section.png";
import speaker41 from "../Assets/Page 08/sp1.png";
import des41 from "../Assets/Page 08/des1.png";
import speaker42 from "../Assets/Page 08/sp2.png";
import des42 from "../Assets/Page 08/des2.png";
import speaker43 from "../Assets/Page 08/sp3.png";
import des43 from "../Assets/Page 08/des3.png";
import section4 from "../Assets/Page 08/kenyotespeaker.png";
import PresentSpeakerdes from '../Assets/spe1.png'
import PastSpeakerdes from '../Assets/spe2.png'
import FutureSpeakerdes from '../Assets/spe3.png'
const SpeakerProfile = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        //minWidth: "100vw",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover", //
        //backgroundSize: "contain", // Both above and this works
        //backgroundPosition: "center", // Center the background image
        backgroundRepeat: "round", // Prevent image from repeating
      }}
    >
      <img
        src={leftlogo}
        style={{ position: "absolute", right: 25, top: 20, width: "20%" }}
      ></img>
      <div style={styles.speakersList}>
        {/* Firsst page speakers on pdf */}
        {/* <img src={section1} style={styles.section}></img>
        <div style={styles.rightspeaker}>
          <img src={speaker1des} style={styles.rightspeakerdes}></img>

          <img
            src={speaker1}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker2}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={speaker2des} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={speaker3des} style={styles.rightspeakerdes}></img>
          <img
            src={speaker3}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker4}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={speaker4des} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={speaker5des} style={styles.rightspeakerdes}></img>
          <img
            src={speaker5}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker6}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={speaker6des} style={styles.leftspeakerdes}></img>
        </div>
      
        <img src={section2} style={styles.section1}></img>
        <div style={styles.rightspeaker}>
          <img src={des21} style={styles.rightspeakerdes}></img>

          <img
            src={speaker21}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker22}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des22} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={des23} style={styles.rightspeakerdes}></img>
          <img
            src={speaker23}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker24}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des24} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={des25} style={styles.rightspeakerdes}></img>
          <img
            src={speaker25}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
       
        <img src={section3} style={styles.section1}></img>
        <div style={styles.rightspeaker}>
          <img src={des31} style={styles.rightspeakerdes}></img>

          <img
            src={speaker31}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker32}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des32} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={des33} style={styles.rightspeakerdes}></img>
          <img
            src={speaker33}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker34}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des34} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={des35} style={styles.rightspeakerdes}></img>
          <img
            src={speaker35}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker36}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des36} style={{...styles.leftspeakerdes,width:'30%'} }></img>
        </div>

       
        <img src={section4} style={styles.section2}></img>
        <div style={styles.rightspeaker}>
          <img src={des41} style={styles.rightspeakerdes}></img>

          <img
            src={speaker41}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker42}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des42} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={des43} style={styles.rightspeakerdes}></img>
          <img
            src={speaker43}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div> */}
        {/* Drive the present speakers */}
        <img src={section4} style={styles.section2}></img>
         <h3 style={{fontFamily:'InterstateBold', color:'#00FFEC',textAlign: '-webkit-center'   }}>DRIVE THE PRESENT</h3>
         <div style={styles.centralspeaker}>
         <img
            src={speaker41}
            style={{ ...styles.rightspeakerimage}}
          ></img>
         <img src={PresentSpeakerdes} style={{...styles.rightspeakerdes,marginTop:10}}></img>
         </div>
                {/* Third page speakers on pdf */}
                <img src={section3} style={styles.section1}></img>
        <div style={styles.rightspeaker}>
          <img src={des31} style={styles.rightspeakerdes}></img>

          <img
            src={speaker31}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker32}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des32} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={des33} style={styles.rightspeakerdes}></img>
          <img
            src={speaker33}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker34}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des34} style={styles.leftspeakerdes}></img>
        </div>
        {/* <div style={styles.rightspeaker}>
          <img src={des35} style={styles.rightspeakerdes}></img>
          <img
            src={speaker35}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div> */}
        <div style={{...styles.rightspeaker, marginBottom:40}}>
         
          <img src={des36} style={{...styles.rightspeakerdes,width:'30%' ,} }></img>
          <img
            src={speaker36}
            style={{ ...styles.rightspeakerimage,marginLeft: 20  }}
          ></img>
        </div>
        {/* Learn from the past */}
        <img src={section4} style={styles.section2}></img>
        
         <h3 style={{fontFamily:'InterstateBold', color:'#00FFEC',textAlign: '-webkit-center'   }}>LEARN FROM THE PAST</h3>
         <div style={styles.centralspeaker}>
         <img
            src={speaker42}
            style={{ ...styles.rightspeakerimage}}
          ></img>
         <img src={PastSpeakerdes} style={{...styles.rightspeakerdes,marginTop:10}}></img>
         </div>
         
         <img src={section2} style={styles.section3}></img>
        <div style={styles.rightspeaker}>
          <img src={des21} style={styles.rightspeakerdes}></img>

          <img
            src={speaker21}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker22}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des22} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={des23} style={styles.rightspeakerdes}></img>
          <img
            src={speaker23}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker24}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={des24} style={styles.leftspeakerdes}></img>
        </div>
        <div style={{...styles.rightspeaker, marginBottom:40}}>
          <img src={des25} style={styles.rightspeakerdes}></img>
          <img
            src={speaker25}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker4}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={speaker4des} style={styles.leftspeakerdes}></img>
        </div>
          {/* Build the future */}
          <img src={section4} style={styles.section2}></img>
         <h3 style={{fontFamily:'InterstateBold', color:'#00FFEC',textAlign: '-webkit-center'   }}>BUILD THE FUTURE</h3>
         <div style={styles.centralspeaker}>
         <img
            src={speaker43}
            style={{ ...styles.rightspeakerimage}}
          ></img>
         <img src={des43} style={{...styles.rightspeakerdes,marginTop:10}}></img>
         </div>
         

{/* Firsst page speakers on pdf */}
        <img src={section1} style={styles.section}></img>
        <div style={styles.rightspeaker}>
          <img src={speaker1des} style={styles.rightspeakerdes}></img>

          <img
            src={speaker1}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker2}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={speaker2des} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={speaker3des} style={styles.rightspeakerdes}></img>
          <img
            src={speaker3}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker6}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={speaker6des} style={styles.leftspeakerdes}></img>
        </div>
        <div style={styles.rightspeaker}>
          <img src={speaker5des} style={styles.rightspeakerdes}></img>
          <img
            src={speaker5}
            style={{ ...styles.rightspeakerimage, marginLeft: 20 }}
          ></img>
        </div>
        <div style={styles.leftspeaker}>
          <img
            src={speaker7}
            style={{ ...styles.leftspeakerimage, marginRight: 20 }}
          ></img>
          <img src={speaker7des} style={styles.leftspeakerdes}></img>
        </div>
     

 







      </div>
    </div>
  );
};

const styles = {
  speakersList: {
    height: "85vh",
    overflowY: "auto",
    //backgroundColor:'red'
    //border: '1px solid black',
  },

  section: {
    width: "40%",
    marginLeft: 20,
    marginBottom: 25,
    marginTop:30,
  },
  section1: {
    width: "40%",
    marginLeft: 20,
    marginBottom: 25,
    marginTop: 30,
  },
  section3: {
    width: "30%",
    marginLeft: 20,
    marginBottom: 25,
    marginTop: 30,
  },
  section2: {
    width: "25%",
    marginLeft: 20,
    marginBottom: 25,
    marginTop: 30,
  },
  
  rightspeaker: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    marginLeft: 20,
    width: "80%",
    marginBottom: 30,

    //width:"60%",

    //marginLeft:70,
  },
  centralspeaker: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    marginLeft: 20,
    width: "80%",
    marginBottom: 15,

    //width:"60%",

    //marginLeft:70,
  },
  rightspeakerimage: {
    width: "20%",
    height: "20%",
  },
  rightspeakerdes: {
    width: "40%",
    //margin:10,
    //height:"20%"
  },
  leftspeaker: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    marginLeft: 50,
    width: "80%",
    marginBottom: 30,
  },
  leftspeakerimage: {
    width: "20%",
    height: "20%",
  },
  leftspeakerdes: {
    width: "40%",
    //height:"20%"
  },
};
export default SpeakerProfile;
