import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import VideoRoute from './Video'; // Ensure this import is correct
import LandingPage from './LandingPage'; // Ensure this import is correct
import Home from './Agenda'; // Ensure this import is correct
import Search from './Feedback'; // Ensure this import is correct
import Settings from './Menu'; // Ensure this import is correct
import BottomNav from './BottomNav'; // Ensure this import is correct
import { useNavigate } from 'react-router-dom';
import { Padding } from '@mui/icons-material';
function DemoPage() {
  const [view, setView] = useState(null);
const navigate = useNavigate();
  // Show normal app layout or video based on view state
  const renderContent = () => {
    if (view === 'before') {
      navigate('/')
    } else if (view === 'after') {
      navigate('/landing')
    }
    return null;
  };

  return (
    <div style={styles.cont}>
      <h1>Demo</h1>
      <button style={styles.button} onClick={() => setView('before')}>Before September 29, 2024</button>
      <button  style={styles.button}  onClick={() => setView('after')}>After September 29, 2024</button>
      {renderContent()}
    </div>
  );
}

const styles = ({
  cont :{
    display:'flex',
    flexDirection:'column',
    alignContent:'center',
    alignItems:'center'
  },
  button:{
    width:"50%",
    margin:10,
    padding:20,
  }
})
export default DemoPage;
