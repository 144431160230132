import React,{useEffect} from 'react';
import backgroundImage from '../Assets/Page 02/agenda.png';
import leftlogo from '../Assets/Page 01/bottomlogo.png'
import agendaText from '../Assets/Page 02/text.png'

const Agenda = () => {
  useEffect(()=>{
    //window.scrollTo(0, 190);           //it is doing nothing but just scrolling up thats it .
})
  return (
    <div
    style={{
      display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // 
     
        backgroundRepeat: "round", 
        overflow: "hidden", // Prevent scrolling

      
    }}
  >
    <p style={{fontFamily:'InterstateBold', color:'#FFC72C', position:'absolute', top:10, left:25, fontSize:20}}>EVENT AGENDA</p>
    <img src={leftlogo} style={{position:'absolute', right:25, top:20, width:'20%'}}></img>
    <img src={agendaText} style={{position:'absolute', width:'80%', height:'78%' }}></img>
   
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
  },
};

export default Agenda;




// import React, { useEffect } from 'react';
// import backgroundImage from '../Assets/Page 02/agenda.png';
// import leftlogo from '../Assets/Page 01/bottomlogo.png';
// import agendaText from '../Assets/Page 02/text.png';

// const Agenda = () => {
//   // Function to request fullscreen
//   const requestFullscreen = () => {
//     const element = document.documentElement; // Get the root element (the whole page)

//     if (element.requestFullscreen) {
//       element.requestFullscreen();
//     } else if (element.webkitRequestFullscreen) {
//       element.webkitRequestFullscreen(); // Safari compatibility
//     } else if (element.msRequestFullscreen) {
//       element.msRequestFullscreen(); // IE/Edge compatibility
//     } else if (element.mozRequestFullScreen) {
//       element.mozRequestFullScreen(); // Firefox compatibility
//     }
//   };
//   useEffect(()=>{
//     requestFullscreen()
//   },[])

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         position: "relative",
//         alignItems: "center",
//         justifyContent: "center",
//         minHeight: "100vh",
//         backgroundImage: `url(${backgroundImage})`,
//         backgroundSize: "cover",
//         backgroundRepeat: "round",
//         overflow: "hidden", // Prevent scrolling
//       }}
//     >
//       <p style={{fontFamily: 'InterstateBold', color: '#FFC72C', position: 'absolute', top: 10, left: 25, fontSize: 20}}>EVENT AGENDA</p>
//       <img src={leftlogo} alt="Left Logo" style={{position: 'absolute', right: 25, top: 20, width: '20%'}} />
//       <img src={agendaText} alt="Agenda Text" style={{position: 'absolute', width: '80%', height: '78%'}} />
      
//       {/* Button to trigger fullscreen mode */}
//       {/* <button 
//         onClick={requestFullscreen}
//         style={{
//           position: 'absolute', 
//           bottom: 20, 
//           padding: '10px 20px', 
//           fontSize: '16px', 
//           backgroundColor: '#FFC72C', 
//           border: 'none',
//           borderRadius: '5px',
//           cursor: 'pointer'
//         }}
//       >
//         Go Fullscreen
//       </button> */}
//     </div>
//   );
// };

// export default Agenda;

