// import React, { useState } from 'react';
// import ReactPlayer from 'react-player';
// import idleVideo from '../Assets/updatedvid.mp4';
// import backgroundImage from '../Assets/Page 03/demo.png';
// import playImg from '../Assets/playicon.png';
// import logo from '../Assets/Page 01/loggo.png'

// const VideoPage = () => {
//   const [playing, setPlaying] = useState(false);

//   const handlePlayClick = () => {
//     setPlaying(true);
//   };

//   const handleVideoEnd = () => {
//     setPlaying(false);
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         position: "relative",
//         alignItems: "center",
//         justifyContent: "center",
//         minHeight: "100vh",
//         backgroundImage: `url(${backgroundImage})`,
//         backgroundSize: "cover",
//         backgroundRepeat: "round",
//       }}
//     >
//       <img src={logo} style={{position:'absolute', left:15, width:'45%',top:20}}></img>
//       <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
//         <ReactPlayer
//           url={idleVideo}
//           playing={playing}
//           controls={true}
//           width="100%"
//           height="100%"
//           muted={false}
//           // onEnded={handleVideoEnd} // Call handleVideoEnd when video ends
//         />
//         {/* {!playing && (
//           <img
//             src={playImg}
//             onClick={handlePlayClick}
//             style={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               cursor: 'pointer',
//               width:'10%'
//             }}
//             alt="Play"
//           />
//         )} */}
//       </div>
//     </div>
//   );
// };

// export default VideoPage;






// import React, { useState, useRef } from 'react';
// import ReactPlayer from 'react-player';
// import idleVideo from '../Assets/updatedvid.mp4';
// import backgroundImage from '../Assets/Page 03/demo.png';
// import logo from '../Assets/Page 01/loggo.png'

// const VideoPage = () => {
//   const [playing, setPlaying] = useState(false);
//   const [hasPlayed, setHasPlayed] = useState(false); // To prevent seeking on subsequent play clicks
//   const playerRef = useRef(null); // Use ref to access the player

//   const handlePlay = () => {
//     if (playerRef.current && !hasPlayed) {
//       playerRef.current.seekTo(2); // Skip to 2 seconds
//       setHasPlayed(true); // Set to true so it only seeks the first time
//     }
//     setPlaying(true);
//   };

//   const handleVideoEnd = () => {
//     setPlaying(false);
//     setHasPlayed(false); // Reset when video ends, to allow seeking again if played
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         position: "relative",
//         alignItems: "center",
//         justifyContent: "center",
//         minHeight: "100vh",
//         backgroundImage: `url(${backgroundImage})`,
//         backgroundSize: "cover",
//         backgroundRepeat: "round",
//       }}
//     >
//       <img src={logo} style={{position:'absolute', left:15, width:'45%',top:20}} alt="Logo" />
//       <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
//         <ReactPlayer
//           ref={playerRef} // Set ref to the player
//           url={idleVideo}
//           playing={playing}
//           controls={true}
//           width="100%"
//           height="100%"
//           muted={false}
//           onPlay={handlePlay} // Call handlePlay when the video starts playing
//           onEnded={handleVideoEnd} // Reset state when video ends
//         />
//       </div>
//     </div>
//   );
// };

// export default VideoPage;



import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import idleVideo from '../Assets/updatedvid.mp4';
import backgroundImage from '../Assets/Page 03/demo.png';
import logo from '../Assets/Page 01/loggo.png'

const VideoPage = () => {
  const [playing, setPlaying] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false); 
  const playerRef = useRef(null); 

  const handlePlay = () => {
    if (playerRef.current && !hasPlayed) {
      playerRef.current.seekTo(2); 
      setHasPlayed(true); 
    }
    setPlaying(true);
  };

  const handleVideoEnd = () => {
    setPlaying(false);
    setHasPlayed(false); // Reset w
  };

  const handleSeek = (seekTime) => {
    if (seekTime < 2 && playerRef.current) {
      playerRef.current.seekTo(2); // 
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "round",
        
      }}
    >
      <img src={logo} style={{position:'absolute', left:15, width:'45%',top:20}} alt="Logo" />
      <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
        <ReactPlayer
          ref={playerRef} // Set ref to the player
          url={idleVideo}
          playing={playing}
          controls={true}
          width="100%"
          height="100%"
          ///light="https://operationsmile.ae/wp-content/uploads/2021/08/Patient-Deron_2cropped--300x222.jpeg"
          muted={false}
          onPlay={handlePlay} 
          onSeek={handleSeek} 
          onEnded={handleVideoEnd} 
        />
      </div>
    </div>
  );
};

export default VideoPage;
