import { BrowserRouter as Router, Route, Routes, Navigate , Link, useLocation} from 'react-router-dom';
import backgroundImage from '../Assets/Page 01/bg.png';
import agendaImg from '../Assets/newbuttons/Asset 7.png';
import speakerImg from '../Assets/newbuttons/Asset 9.png'
import smileImg from '../Assets/newbuttons/Asset 11.png';
import feedbackImg from '../Assets/newbuttons/Asset 10.png'
import menuImg from '../Assets/newbuttons/Asset 8.png';
import bottomLogo from '../Assets/Page 01/bottomlogo.png'
import { hexToRgb } from '@mui/material';
import { height, width } from '@mui/system';

function LandingPage() {
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      position: "relative",
      alignItems: "center",
      backgroundSize: "cover",
      minHeight: "100vh",
      justifyContent: "center",
       //backgroundColor: "lightblue",
      backgroundImage: `url(${backgroundImage})`,
      //top:-10,

      
    }}
  >
      <div style={styles.buttonList}>
        <Link to="/agenda" className="landing-link" >
          <img src={agendaImg} alt="Home" style={styles.buttonimage}/>
        </Link>
        <Link to="/menu" className="landing-link">
          <img src={menuImg} alt="Settings" style={styles.buttonimage} />
        </Link>
        <Link to="/speaker" className="landing-link">
          <img src={speakerImg} alt="Search" style={styles.buttonimage}/>
        </Link>
        <Link to="/feedback" className="landing-link" >
          <img src={feedbackImg} alt="Settings" style={styles.buttonimage} />
        </Link>
        <a href="https://operationsmile.ae/donate/" className="landing-link" target="_blank" rel="noopener noreferrer" >
  <img src={smileImg} alt="Settings" style={styles.buttonimage} />
</a>


      </div>
      <img src={bottomLogo} style={styles.bottomLogo}></img>
    </div>
  );
}


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  buttonList: {
    display:'flex',
    justifyContent:'space-around',
   // width:'60%',
    flexDirection:'column',
    marginLeft:'30%',
    marginTop:'50%',
    gap:'10px'
  },
 buttonimage:{
objectFit:'contain',
width:'80%',
//outline:'none'
//marginTop:'6%'
//height:'80px'
 },
 buttonimage1:{
  objectFit:'contain',
  width:'80%',
  marginTop:'6%'
  //height:'80px'
   },
 bottomLogo:{
  width:'20%',
  position:'absolute',
  left:60,
  bottom:70
 }

};

export default LandingPage;











// import { useNavigate } from 'react-router-dom';
// import backgroundImage from '../Assets/Page 01/bg.png';
// import agendaImg from '../Assets/newbuttons/Asset 7.png';
// import speakerImg from '../Assets/newbuttons/Asset 9.png';
// import smileImg from '../Assets/newbuttons/Asset 11.png';
// import feedbackImg from '../Assets/newbuttons/Asset 10.png';
// import menuImg from '../Assets/newbuttons/Asset 8.png';
// import bottomLogo from '../Assets/Page 01/bottomlogo.png';

// function LandingPage() {
//   const navigate = useNavigate();

//   const handleNavigation = (path) => {
//     navigate(path);
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         position: "relative",
//         alignItems: "center",
//         backgroundSize: "cover",
//         minHeight: "100vh",
//         justifyContent: "center",
//         backgroundImage: `url(${backgroundImage})`,
//       }}
//     >
//       <div style={styles.buttonList}>
//         <img 
//           src={agendaImg} 
//           alt="Agenda" 
//           style={styles.buttonimage} 
//           onClick={() => handleNavigation('/agenda')} 
//         />
//         <img 
//           src={menuImg} 
//           alt="Menu" 
//           style={styles.buttonimage} 
//           onClick={() => handleNavigation('/menu')} 
//         />
//         <img 
//           src={speakerImg} 
//           alt="Speaker" 
//           style={styles.buttonimage} 
//           onClick={() => handleNavigation('/speaker')} 
//         />
//         <img 
//           src={feedbackImg} 
//           alt="Feedback" 
//           style={styles.buttonimage} 
//           onClick={() => handleNavigation('/feedback')} 
//         />
//         <a 
//           href="https://operationsmile.ae/donate/" 
//           target="_blank" 
//           rel="noopener noreferrer"
//         >
//           <img 
//             src={smileImg} 
//             alt="Donate" 
//             style={styles.buttonimage} 
//           />
//         </a>
//       </div>
//       <img src={bottomLogo} style={styles.bottomLogo} alt="Bottom Logo" />
//     </div>
//   );
// }

// const styles = {
//   buttonList: {
//     display: 'flex',
//     justifyContent: 'center', // Center the button list
//     flexDirection: 'column',
//     marginLeft: '30%',
//     marginTop: '50%',
//     gap: '10px', // Add space between images
//   },
//   buttonimage: {
//     objectFit: 'contain',
//     width: '80%',
//     cursor: 'pointer', // Optional: Add cursor pointer for better UX
//   },
//   bottomLogo: {
//     width: '20%',
//     position: 'absolute',
//     left: 60,
//     bottom: 70,
//   },
// };

// export default LandingPage;
