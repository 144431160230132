import React from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyAZVvxgO2fjFVFSK77mNK9gTSZ_s_uLhSU",
  authDomain: "mubadala-1a3ad.firebaseapp.com",
  projectId: "mubadala-1a3ad",
  storageBucket: "mubadala-1a3ad.appspot.com",
  messagingSenderId: "807256179479",
  appId: "1:807256179479:web:c905d90f1387d5660ced52",
  measurementId: "G-5CM3T9XQD7"
};


const app = initializeApp(firebaseConfig);


const db = getFirestore(app);


const analytics = getAnalytics(app);


const userCollection = collection(db, 'Mubadala_feedbackData');


async function getAllUserData() {
  const querySnapshot = await getDocs(userCollection);
  const userData = querySnapshot.docs.map(doc => doc.data());
  return userData;
}


function convertToCSV(data) {
  if (data.length === 0) {
    return '';
  }

  const orderedKeys = ["email","groupProcurementOrganization", "sustainableProcurement", "futureProofingProcurement", "adaptionToAI", "feedbackText"];


  const headers = orderedKeys.join(",");


  const rows = data.map(item => 
    orderedKeys.map(key => 
      item[key] !== undefined ? item[key] : ''  
    ).join(",")
  );

  return [headers, ...rows].join("\n");
}


function downloadCSV(csv, filename) {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}


const ExportButton = () => {
  const handleExport = async () => {
    const userData = await getAllUserData();
    if (userData.length > 0) {
      const csv = convertToCSV(userData);
      downloadCSV(csv, 'Mubadala_feedbackData.csv');
    } else {
      alert('No data available to export.');
    }
  };

  return (
    <div style={{
        backgroundColor: "ButtonShadow",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundSize: "cover",
        minHeight: "100vh",
        justifyContent: "center",
      }}>
      <button style={{ padding: 20, fontWeight: 'bold' }} onClick={handleExport}>
        Export Data to CSV
      </button>
    </div>
  );
};

export default ExportButton;
