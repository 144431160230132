// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAZVvxgO2fjFVFSK77mNK9gTSZ_s_uLhSU",
  authDomain: "mubadala-1a3ad.firebaseapp.com",
  projectId: "mubadala-1a3ad",
  storageBucket: "mubadala-1a3ad.appspot.com",
  messagingSenderId: "807256179479",
  appId: "1:807256179479:web:c905d90f1387d5660ced52",
  measurementId: "G-5CM3T9XQD7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics (optional)
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Reference to the feedback collection in Firestore
const userCollection = collection(db, "Mubadala_feedbackData");

// Function to post feedback data to Firestore
async function postFeedbackData({
  groupProcurementOrganization,
  sustainableProcurement,
  futureProofingProcurement,
  adaptionToAI,
  feedbackText,
  email,
}) {
  try {
    await addDoc(userCollection, {
      groupProcurementOrganization,
      sustainableProcurement,
      futureProofingProcurement,
      adaptionToAI,
      feedbackText,
      email,
      timestamp: new Date(), // Add timestamp
    });
    console.log("Feedback saved successfully");
  } catch (error) {
    console.error("Error saving feedback: ", error);
  }
}

export { postFeedbackData };
