// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
// import './App.css'; // Custom CSS

// // Import images or use public URLs
// import HomeIcon from './Assets/3.png';
// import SearchIcon from './Assets/4.png';
// import SettingsIcon from './Assets/5.png';
// import LandingPageIcon from './Assets/4.png'; // Icon to return to Landing Page

// // Landing Page (without bottom bar)
// function LandingPage() {
//   return (
//     <div className="landing-page">
//       <h1>Select a Page</h1>
//       <div className="landing-buttons">
//         <Link to="/" className="landing-link">
//           <img src={HomeIcon} alt="Home" className="landing-icon" />
//         </Link>
//         <Link to="/search" className="landing-link">
//           <img src={SearchIcon} alt="Search" className="landing-icon" />
//         </Link>
//         <Link to="/settings" className="landing-link">
//           <img src={SettingsIcon} alt="Settings" className="landing-icon" />
//         </Link>
//       </div>
//     </div>
//   );
// }

// // Individual Pages
// function Home() {
//   return <h1>Home Screen</h1>;
// }

// function Search() {
//   return <h1>Search Screen</h1>;
// }

// function Settings() {
//   return <h1>Settings Screen</h1>;
// }

// // Bottom Navigation Component
// function BottomNav() {
//   const location = useLocation();

//   // Hide bottom bar on Landing Page
//   if (location.pathname === '/landing') return null;

//   return (
//     <div className="bottom-nav">
//       <Link to="/" className="nav-link">
//         <img src={HomeIcon} alt="Home" className="nav-icon" />
//       </Link>
//       <Link to="/search" className="nav-link">
//         <img src={SearchIcon} alt="Search" className="nav-icon" />
//       </Link>
//       <Link to="/settings" className="nav-link">
//         <img src={SettingsIcon} alt="Settings" className="nav-icon" />
//       </Link>
//       <Link to="/landing" className="nav-link">
//         <img src={LandingPageIcon} alt="Landing" className="nav-icon" />
//       </Link>
//     </div>
//   );
// }

// // Main App Component
// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/landing" element={<LandingPage />} />
//         <Route path="/" element={<Home />} />
//         <Route path="/search" element={<Search />} />
//         <Route path="/settings" element={<Settings />} />
//       </Routes>
//       <BottomNav /> {/* Conditionally rendered based on the location */}
//     </Router>
//   );
// }

// export default App;









import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate , Link, useLocation} from 'react-router-dom';
import './App.css'; // Custom CSS
import HomeIcon from './Assets/3.png';
import SearchIcon from './Assets/4.png';
import SettingsIcon from './Assets/5.png';
import LandingPageIcon from './Assets/4.png';
import DemoPage from './Components/Demo';
import VideoPage from './Components/Video';
import LandingPage from './Components/LandingPage';
import Agenda from './Components/Agenda';
import Menu from './Components/Menu';
import SpeakerProfile from './Components/SpeakerProfile';
import Feedback from './Components/Feedback';
import ExportButton from './Components/GetData';
import iconsImg from './Assets/Page 02/icons.png'
import menuImg from './Assets/menu.png'
import SmileImg from './Assets/smile.png'
import AgendaImg from './Assets/agenda.png';
import homeIMg from './Assets/home.png';
import SpeakerImg from './Assets/speaker.png'
import BottomImg from './Assets/bg.png'
// VideoRoute Component (before September 23, 2024)





function Search() {
  return <h1>Search Screen</h1>;
}



// Bottom Navigation Component
function BottomNav() {
  const location = useLocation();

  if (location.pathname === '/' || location.pathname==='/demo' || location.pathname==='/landing' || location.pathname==='/getdata') return null;

  return (
    <div className="bottom-nav" style={{ backgroundImage: `url(${BottomImg})`,   backgroundSize: "cover"}}>
       <Link to="/landing" className="nav-link">
      <button className="nav-icon" style={{backgroundColor:'transparent', border:0, backgroundImage: `url(${homeIMg})`,   backgroundSize: "cover",width:'25px',height:'25px'}}></button>
      </Link>
      <Link to="/agenda" className="nav-link">
      <button className="nav-icon" style={{backgroundColor:'transparent', border:0, backgroundImage: `url(${AgendaImg})`,   backgroundSize: "cover",width:'25px',height:'25px'}}></button>
      </Link>
     
      <Link to="/menu" className="nav-link">
       
       <button className="nav-icon" style={{backgroundColor:'transparent', border:0, backgroundImage: `url(${SmileImg})`,   backgroundSize: "cover",width:'25px',height:'25px'}}></button>
     </Link>
     
      <Link to="/speaker" className="nav-link">
      <button className="nav-icon" style={{backgroundColor:'transparent', border:0, backgroundImage: `url(${SpeakerImg})`,   backgroundSize: "cover",width:'18px',height:'25px'}}></button>
      </Link>
      <Link to="/feedback" className="nav-link">
      <button className="nav-icon" style={{backgroundColor:'transparent', border:0, backgroundImage: `url(${menuImg})`,   backgroundSize: "cover",width:'25px',height:'25px'}}></button>
      </Link>
    </div>
  );
}

// Main App Component with date-based redirection
function App() {
  const setTitle=()=>{
    document.title="Mubadala"
   
  }
  useEffect(()=>{
    setTitle();
    
  })
  const currentDate = new Date();
  console.log('current', currentDate)
  const redirectDate = new Date('2024-09-30T00:00:00+04:00');
  console.log('current2', redirectDate)

  if (currentDate < redirectDate) {
    // Redirect to the VideoRoute before September 23, 2024
    return (
      <Router>
        <Routes>
          <Route path='/getdata' element={<ExportButton></ExportButton>} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/speaker" element={<SpeakerProfile />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/feedback" element={<Feedback />} />
          <Route path='/demo' element={<DemoPage></DemoPage>}/>
          {/* <Route path="*" element={<Navigate to="/video" replace />} /> */}
          <Route path="/" element={<VideoPage/>} />
        </Routes>
      
        < BottomNav/>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
      <Route path="/" element={<LandingPage />} />

        <Route path="/landing" element={<LandingPage />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/speaker" element={<SpeakerProfile />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path='/getdata' element={<ExportButton></ExportButton>} />
      </Routes>
      <BottomNav />
    </Router>
  );
}

export default App;












