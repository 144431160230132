import React, { useState, useLayoutEffect } from 'react';
import backgroundImage from '../Assets/Page 04/bg.png';
import leftlogo from '../Assets/Page 01/bottomlogo.png';
import textAreaImg from '../Assets/Page 04/textarea.png';
import emailImg from '../Assets/Page 04/email.png';
import submitButton from '../Assets/Page 04/submitbutton.png';
import './feed.css';
import { postFeedbackData } from '../firebase'; // Import Firebase function
import { BorderColor, Opacity } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Feedback = () => {
  const [sustainableProcurement, setSustainableProcurement] = useState(false);
  const [futureProofingProcurement, setfutureProofingProcurement] = useState(false);
  const [groupProcurementOrganization , setGroupProcurementOrganization]= useState(false)
  const [adaptionToAI, setAdaptiontoAI] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async () => {
    if (emailError || !email) {
      alert("Please enter a valid email address.");
      return;
    }

    const feedbackData = {
      groupProcurementOrganization,
      sustainableProcurement,
      futureProofingProcurement,
      adaptionToAI,
      feedbackText,
      email,
    };

    try {
      console.log('feed',feedbackData)
       await postFeedbackData(feedbackData); 
      alert("Your feedback is submitted. Thankyou!");
      navigate('/landing')
    } catch (error) {
      console.error("Error submitting feedback", error);
    }
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(emailInput) ? 'Please enter a valid email address' : '');
  };

  return (
    <div style={styles.container}>
      <p style={styles.header}>FEEDBACK</p>
      <img src={leftlogo} style={styles.logo} alt="logo" />

     

      <div style={{...styles.checkboxContainer,top: 20,}}>
      <p style={{...styles.subText}}>
        Share your interests, and we'll reach out about future initiatives and projects that align with them.
      </p>
      <label style={styles.customCheckboxLabel}>
          <input type="checkbox" onChange={() => setGroupProcurementOrganization(!groupProcurementOrganization)} style={styles.checkbox} />
          <span className="custom-checkbox"></span> Group Procurement Organization
        </label>
        <label style={styles.customCheckboxLabel}>
          <input type="checkbox" onChange={() => setSustainableProcurement(!sustainableProcurement)} style={styles.checkbox} />
          <span className="custom-checkbox"></span> Sustainable Procurement
        </label>
        <label style={styles.customCheckboxLabel}>
          <input type="checkbox" onChange={() => setfutureProofingProcurement(!futureProofingProcurement)} style={styles.checkbox} />
          <span className="custom-checkbox"></span> Future-Proofing Procurement
        </label>
        <label style={styles.customCheckboxLabel}>
          <input type="checkbox" onChange={() => setAdaptiontoAI(!adaptionToAI)} style={{...styles.checkbox}} />
          <span className="custom-checkbox"></span> Adaption to AI
        </label>
      <br></br>

      {/* <div style={{...styles.formGroup,marginTop: 20,}}> */}

<div>        <label style={{...styles.label,marginTop:20}}>Event Feedback<span style={{color:'#FFC72C'}}>*</span></label>
        <textarea
          style={{ ...styles.textarea,marginTop:10,marginBottom:10,color:'white', backgroundImage: `url(${textAreaImg})`, backgroundColor:'transparent',border:0.02,borderColor:'#00FFEC',borderStyle:'solid',backgroundPosition:'bottom' }}
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="Give your feedback..."
        />
      </div>

      {/* <div style={styles.formGroup}> */}
      <div>
        <label style={styles.label}>Email<span style={{color:'#FFC72C'}}>*</span></label>
        <input
          type="email"
          style={{ ...styles.input,marginTop:5,marginBottom:20,   color:'white', backgroundImage: `url(${emailImg})`,backgroundColor:'transparent',border:0.02,borderColor:'#00FFEC',borderStyle:'solid',backgroundPosition:'bottom' }}
          value={email}
       
          onChange={handleEmailChange}
          placeholder="Enter your email"
        />
        {emailError && <p style={styles.error}>{emailError}</p>}
       
      </div>
{/* <div style={styles.formGroup}> */}
<div>
<img src={submitButton} style={{...styles.submit,backgroundColor:'transparent',border:0.2,borderColor:'#FFC72C',borderStyle:'solid'}} alt="Submit" onClick={handleSubmit} />
</div>
</div>
      
    </div>
  );
};




const styles = {
  container: {
    // display: 'flex',
    // flexDirection: 'column',
    // position: 'relative',
    // alignItems: 'center',
    // backgroundSize: 'cover',
    // minHeight: '100vh',
    // justifyContent: 'center',
    // backgroundImage: `url(${backgroundImage})`,
    // backgroundRepeat: "round", 
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover", // 
 
    backgroundRepeat: "round", 

  },
  header: {
    fontFamily: 'InterstateBold',
    color: '#FFC72C',
    position: 'absolute',
    top: 10,
    left: 25,
    fontSize: 20,
  },
  logo: {
    position: 'absolute',
    right: 25,
    top: 20,
    width: '20%',
    
  },
  subText: {
    fontFamily: 'InterstateLight',
    color: 'white',
    width: '75%',
    marginInlineEnd: 'auto',
    //top:10,
    left:40,
    //marginTop:'-10%',
   // position: 'absolute',
    //bottom:20,
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    marginLeft: 20,
    //backgroundColor:'red',
    marginTop:50,
    //position:'absolute',
    minHeight:'100vh',
    ///marginBottom:140,
    maxWidth:'90%'
    
    
  },
  customCheckboxLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    fontSize: '16px',
    color: 'white',
    fontFamily: 'InterstateLight',
   
  },
  checkbox: {
    display: 'none',
  },
  formGroup: {
  display:'flex', flexDirection:'column',alignSelf:'start' ,marginLeft: '10%',
    //marginLeft: 20,
    marginBottom: 20,
    width:'80%'
  },
  label: {
    color: 'white',
    fontFamily: 'InterstateLight',
    //bottom:20,
    marginBottom: 20,
  },
  textarea: {
    width: '100%',
    minHeight: '100px',
    //border: '3px solid #4B6F44', // Red border stays
    //BorderColor:'#4B6F44',
    padding: '10px',
    boxShadow: 'none', // No shadow by default
    outline: 'none', // Removes the default blue border when focused
    transition: 'box-shadow 0.3s ease', // Smooth transition for focus effect
    maxWidth: '-webkit-fill-available',

   
  },
  input: {
    width: '100%',
    padding: '10px',
    paddingTop:'5px',
    //border: '3px solid #4B6F44', 
    marginBottom: '10px',
    boxShadow: 'none', 
    outline: 'none', 
    transition: 'box-shadow 0.3s ease', 
     maxWidth: '-webkit-fill-available',
    //paddingTop:0,

    
  },
  error: {
    color: 'red',
    marginTop: '5px',
  },
  submit: {
    width: '100%',
    cursor: 'pointer',
     maxWidth: '-webkit-fill-available',
     //Opacity:0.5
   // padding:10,
    //marginLeft:20
    
  },
};

// In your CSS file or <style> tag
/* Focus state for textarea and input */


export default Feedback;

/* In your CSS file or a style block */

